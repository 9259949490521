import React from 'react'
import { graphql, navigate } from 'gatsby'
import Layout from '@layouts/layout'
import { Block, Breadcrumbs, PostIntro } from '@components'
import ModuleArea from '@helpers/moduleArea'
import { HelmetDatoCms } from 'gatsby-source-datocms'

const Post = ({ data, pageContext }) => {
  const {
    moduleArea,
    title,
    path,
    excerptNode,
    author,
    meta,
    categories,
    eventTime,
    eventLocation,
    seoMetaTags
  } = data.post

  const { siteUrl } = data.site.siteMetadata

  const postCategories =
    categories.length > 0 &&
    categories.map(category => ({
      title: category.title,
      id: category.id,
      onClick: e => {
        e.preventDefault()
        navigate(`/blog/`, {
          state: {
            category: { ...category }
          }
        })
      }
    }))

  const introConfig = {
    title,
    path,
    excerptNode,
    author,
    meta,
    eventTime,
    eventLocation,
    categories: postCategories,
    isEvent:
      postCategories.findIndex(category => category.title === 'Event') > -1
  }

  return (
    <Layout>
      <HelmetDatoCms defer={false} seo={seoMetaTags} />
      {pageContext?.breadcrumbs && (
        <Block gutters>
          <Breadcrumbs breadcrumbs={pageContext?.breadcrumbs} slim />
        </Block>
      )}
      <Block padding="bottom" gutters>
        <PostIntro {...introConfig} />
      </Block>
      <ModuleArea
        modules={moduleArea}
        post={{ title, path: `${siteUrl}${path}` }}
      />
    </Layout>
  )
}

export const query = graphql`
  query postById($id: String!) {
    post: datoCmsPost(id: { eq: $id }) {
      ...PostIntro
      id
      slug
      ...PostContentModuleArea
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
export default Post
